<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.centerInstitution.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.centerInstitution.list.title"></app-i18n>
      </h1>

      <app-center-institution-list-toolbar></app-center-institution-list-toolbar>
      <app-center-institution-list-filter></app-center-institution-list-filter>
      <app-center-institution-list-table></app-center-institution-list-table>
    </div>
  </div>
</template>

<script>
import CenterInstitutionListFilter from '@/modules/center-institution/components/center-institution-list-filter.vue';
import CenterInstitutionListTable from '@/modules/center-institution/components/center-institution-list-table.vue';
import CenterInstitutionListToolbar from '@/modules/center-institution/components/center-institution-list-toolbar.vue';

export default {
  name: 'app-center-institution-list-page',

  components: {
    [CenterInstitutionListFilter.name]: CenterInstitutionListFilter,
    [CenterInstitutionListTable.name]: CenterInstitutionListTable,
    [CenterInstitutionListToolbar.name]: CenterInstitutionListToolbar,
  },
};
</script>

<style>
</style>
